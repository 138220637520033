<template>
  <!-- src: ShopRegisterIndex -->
  <v-container style="padding-bottom: 24px">
    <!-- 语言切换 -->
    <v-menu
      v-if="data.show_language_switcher == 'on' && allLanguages.length > 1"
      offset-y
      :close-on-click="menuCloseOnClick"
      :elevation="2"
      z-index="99999"
    >
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" style="display: inline-block">
          <span
            class="d-flex align-center"
            style="
              margin-bottom: 10px;
              padding: 10px;
              border: 1px solid #f5f5f5;
              border-radius: 12px;
            "
          >
            <span class="opacity-60">{{ userLanguageObj.name }}</span>
            <i class="las la-angle-down ms-1 fs-12" />
          </span>
        </div>
      </template>

      <v-list class="fs-13">
        <v-list-item
          v-for="(language, i) in allLanguages"
          :key="i"
          class="c-pointer d-flex align-center"
          @click="switchLanguage(language.code)"
        >
          <img
            :src="require(`@/assets/img/flags/${language.flag}.png`)"
            class="me-1 h-10px"
          />
          <v-list-item-title class="fs-13 opacity-60">
            {{ language.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <ShopCommonTopBar />

    <v-row justify="center">
      <v-col
        cols="12"
        lg="6"
        md="8"
        sm="10"
        class="pl-3"
        style="align-items: center; display: flex"
      >
        <span class="mr-2 fs-14">{{ $t('already_a_seller') + '?' }}</span>

        <span
          class="fs-14 bg-dark h-25px"
          style="
            color: #fff;
            cursor: pointer;
            padding: 0 12px;
            border-radius: 20px;
            display: inline-flex;
            align-items: center;
          "
          @click="routeToLogin"
        >
          <img
            :src="require('@/assets/img/shop-to-login.png')"
            style="width: 18px; height: 18px; margin-right: 4px"
            class="ml-1"
          />
          <span style="white-space: nowrap">{{ $t('click_to_log_in') }}</span>
        </span>
      </v-col>
    </v-row>

    <v-row align="center" class="flex-column">
      <v-col cols="12" lg="6" md="8" sm="10" class="mx-auto">
        <v-form lazy-validation @submit.prevent="onSend">
          <v-card elevation="0" style="background-color: transparent">
            <v-card-title style="padding: 0 0 6px">
              <span class="text-h6 fw-600">
                {{ $t('register_seller_account') }}
              </span>
            </v-card-title>
            <v-card-text style="padding: 16px 0">
              <div class="mb-4" v-if="isShowPhone">
                <div class="mb-1 fs-13 fw-500 card-text">
                  {{ $t('phone_number') }}
                </div>
                <vue-tel-input
                  v-model="form.phone"
                  v-bind="mobileInputProps"
                  :auto-format="false"
                  :preferred-countries="availableCountries"
                  @country-changed="onCountryChanged"
                  @validate="phoneValidate"
                >
                  <template slot="arrow-icon">
                    <span style="margin: 0 3px">
                      {{ dialCode ? `+${dialCode}` : '' }}
                    </span>
                    <span class="vti__dropdown-arrow">&nbsp;▼</span>
                  </template>
                </vue-tel-input>
                <div
                  class="v-text-field__details mt-2 pl-3"
                  v-if="$v.form.phone.$error"
                >
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message">
                        {{ $t('this_field_is_required') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-text-field__details mt-2 pl-3"
                  v-if="!$v.form.phone.$error && form.showInvalidPhone"
                >
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message">
                        {{ $t('phone_number_must_be_valid') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-4" v-if="isShowEmail">
                <div class="fs-13 fw-500 card-text">
                  {{ $t('email_address') }}
                </div>
                <v-text-field
                  :placeholder="$t('email_address')"
                  type="email"
                  v-model="form.email"
                  :error-messages="emailErrors"
                  @blur="$v.form.email.$touch()"
                  hide-details="auto"
                  required
                  outlined
                ></v-text-field>
              </div>

              <!-- changge register type -->
              <div
                v-if="showRegisterTypeSwitch"
                class="d-flex justify-end form-group mb-2"
              >
                <v-btn
                  class="p-0 text-reset h-20px"
                  type="button"
                  elevation="0"
                  @click="switchMode"
                  style="background-color: #b4904a"
                >
                  <span style="color: #fff">
                    {{ isShowEmail ? $t('change_phone') : $t('change_email') }}
                  </span>
                  <img
                    :src="require('@/assets/img/change-white.png')"
                    style="width: 14px; height: 14px"
                    class="ml-1"
                  />
                </v-btn>
              </div>
            </v-card-text>
          </v-card>

          <v-row style="margin-top: 100px">
            <v-col cols="5"></v-col>

            <v-col>
              <v-btn
                x-large
                class="px-12 mb-4 w-100"
                elevation="0"
                type="submit"
                color="primary"
                :loading="loading"
                :disabled="loading"
              >
                {{ $t('next') }}

                <i
                  class="la la-arrow-right fs-20"
                  style="position: absolute; right: -40px"
                ></i>
              </v-btn>
            </v-col>
          </v-row>

          <div class="mb-4 text-center" style="color: #a8a8a8">
            {{ $t('by_signing_up_you_agree_to_our') }}
            <router-link
              :to="{
                name: 'CustomPage',
                params: { pageSlug: 'terms' }
              }"
              class="primary--text text-decoration-underline"
            >
              {{ $t('terms_and_conditions') }}
            </router-link>
          </div>

          <div
            class="mb-4 text-center"
            style="
              color: #a8a8a8;
              display: flex;
              align-center: center;
              justify-content: center;
            "
          >
            <img
              :src="require('@/assets/img/svg/card-o.svg')"
              width="22px"
              height="22px"
              class="mr-1"
            />
            <router-link to="/" class="primary--text fw-600">
              {{ $t('switch_to_user') }}
            </router-link>
          </div>
          <div class="text-center text-decoration-underline fs-14">
            <a :href="contactUrl" target="_blank" class="text-reset opacity-60">
              <!-- <i class="la la-phone" /> -->
              <span>{{ $t('help_line_link') }}</span>
              <!-- <span>{{ data.helpline }}</span> -->
            </a>
          </div>
        </v-form>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogSwitch" persistent width="310px">
      <v-card class="dialog-container">
        <div class="dialog-close c-pointer" @click="dialogSwitch = false">
          <i class="la la-close fs-20"></i>
        </div>

        <!-- todo: 百分比 -->
        <drag-verify-img-chip
          ref="dvicChip"
          :imgsrc="require('@/assets/img/verified_image.png')"
          :isPassing.sync="isPassing"
          :barWidth="40"
          :text="$t('please_hold_down_the_slider_and_drag')"
          :successText="$t('verificate_pass')"
          :failTip="$t('verification_fails_tips')"
          :successTip="
            $t('verification_passed_more_than_of_users', { scale: '85%' })
          "
          handlerIcon="la la-arrow-right"
          successIcon="la la-check"
          @refresh="refreshVerifyImage"
          @passcallback="verificationPass"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { VueTelInput } from 'vue-tel-input'

// component
import ShopCommonTopBar from '@/components/shop/ShopCommonTopBar'
import DragVerifyImgChip from '@/components/auth/DragVerifyImgChip'

// vuex
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ShopRegisterIndex',

  components: {
    ShopCommonTopBar,
    VueTelInput,
    DragVerifyImgChip
  },

  data() {
    return {
      data: {},

      menuCloseOnClick: true,

      mobileInputProps: {
        inputOptions: {
          type: 'tel',
          placeholder: 'phone number'
        },
        dropdownOptions: {
          showDialCodeInSelection: false,
          showFlags: true,
          showDialCodeInList: true
        },
        autoDefaultCountry: false,
        validCharactersOnly: true,
        mode: 'international'
      },

      form: {
        phone: '',
        email: '',
        showInvalidPhone: false
      },

      // 是否展示切换手机/邮箱开关
      showRegisterTypeSwitch: false,

      isShowPhone: false,

      isShowEmail: true,

      // 图片验证是否通过
      isPassing: false,

      // dialog 开关
      dialogSwitch: false,

      // 国家区号
      dialCode: '',

      loading: false
    }
  },

  watch: {
    'form.phone'(value) {
      if (String(value).indexOf('0') === 0) {
        this.form.phone = this.form.phone.substring(1)
      }
    }
  },

  computed: {
    ...mapGetters('app', [
      'h5Url',
      'getSellerH5Url',
      'allLanguages',
      'userLanguageObj',
      'availableCountries',
      'contactUrl'
    ]),

    emailErrors() {
      const errors = []
      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      !this.$v.form.email.email &&
        errors.push(this.$i18n.t('this_field_is_required_a_valid_email'))
      return errors
    }
  },

  validations: {
    form: {
      email: { required, email },
      phone: { required }
    }
  },

  methods: {
    ...mapActions('app', ['setLanguage']),

    // 验证图片刷新
    refreshVerifyImage() {},

    // 验证通过
    verificationPass() {
      this.sendVerificationCode()
    },

    // 直接去注册
    toregister() {
      const params = {
        name: 'ShopPersonalInformation',

        query: {}
      }

      if (this.isShowPhone) {
        params.query.type = 'phone'
        params.query.value = `+${this.dialCode}${this.form.phone.replace(
          /\s/g,
          ''
        )}`
      } else {
        params.query.type = 'email'
        params.query.value = this.form.email
      }

      // 如果有邀请码带上邀请码
      if (this.referralCode) {
        params.query = {
          referral_code: this.referralCode
        }
      }

      this.$router.push(params)
    },

    // 根据当前的注册，自动发送对应的验证码
    async sendVerificationCode() {
      this.loading = true

      try {
        const methods = this.isShowPhone ? 'sms/send' : 'email/send'

        const params = {
          tag: 'register'
        }

        if (this.isShowPhone) {
          params.phone = `+${this.dialCode}${this.form.phone.replace(
            /\s/g,
            ''
          )}`
        } else {
          params.email = this.form.email
        }

        const {
          data: { result, message }
        } = await this.call_api('post', methods, params)

        if (result) {
          const params = { name: 'ShopInformation', query: {} }

          // 如果有邀请码带上邀请码
          if (this.referralCode) {
            params.query = {
              referral_code: this.referralCode
            }
          }

          if (this.isShowPhone) {
            params.query.type = 'phone'
            params.query.value = `+${this.dialCode}${this.form.phone.replace(
              /\s/g,
              ''
            )}`
          } else {
            params.query.type = 'email'
            params.query.value = this.form.email
          }

          return this.$router.push(params)
        }

        this.snack({
          message: message,
          color: 'red'
        })
      } catch (error) {
        console.log('send verification code fail: ', error)

        this.snack({
          message: this.$t('verification_code_has_send_fail'),
          color: 'red'
        })
      }

      this.loading = false
    },

    async onSend() {
      if (this.isShowPhone) {
        this.$v.form.$touch('phone')

        if (this.$v.form.phone.$error || this.form.showInvalidPhone) return
      } else {
        this.$v.form.email.$touch()

        if (this.$v.form.email.$error) return
      }

      if (!this.registerVerifySwitch) {
        const {
          data: { result, message }
        } = await this.call_api('post', 'user/check/account', {
          type: this.isShowPhone ? 'phone' : 'email',
          value: this.isShowPhone ? this.form.phone : this.form.email,
          tag: 'register'
        })

        if (result) {
          return this.toregister()
        }

        return this.snack({
          message,
          color: 'red'
        })
      }

      this.verificationPass()
      // this.dialogSwitch = true
      // this.$nextTick(() => {
      //   this.$refs.dvicChip.reset()
      // })
    },

    phoneValidate(phone) {
      this.form.showInvalidPhone = !phone.valid
    },

    // 电话区号改变
    onCountryChanged({ dialCode }) {
      this.dialCode = dialCode
    },

    switchLanguage(locale) {
      if (this.$i18n.locale !== locale) {
        this.setLanguage(locale)
        window.location.reload()
      }
    },

    routeToUser() {
      this.$router.push({ name: 'Home' })
    },

    routeToLogin() {
      if (
        window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        window.location.href = this.getSellerH5Url
      } else {
        window.location.href = this.h5Url
      }
    },

    routeToRegister() {
      this.$router.push({ name: 'ShopInformation' })
    },

    // 注册配置
    async registerConfig() {
      try {
        const {
          data: {
            data: { seller_register_type, seller_register_verify }
          }
        } = await this.call_api('get', `register/config?tag=seller`)

        switch (seller_register_type) {
          case 2:
            this.isShowEmail = false
            this.isShowPhone = true
            break
          case 3:
            this.showRegisterTypeSwitch = true
            break
          default:
            break
        }

        // 注册验证
        this.registerVerifySwitch = !!seller_register_verify
      } catch (error) {
        console.log('register config error: ', error)
      }
    },

    async getDetails() {
      const res = await this.call_api('get', `setting/header`)

      if (res.status === 200) {
        this.data = res.data
      }
    },

    switchMode() {
      if (this.isShowEmail) {
        this.isShowPhone = true
        this.isShowEmail = false
      } else {
        this.isShowPhone = false
        this.isShowEmail = true
      }
    }
  },

  created() {
    const query = this.$route.query

    if (query.referral_code) {
      this.referralCode = query.referral_code
    }

    this.getDetails()
    this.registerConfig()
  }
}
</script>

<style lang="scss" scoped>
.dialog-container {
  padding: 30px;
}

.dialog-close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 6px;
}

::v-deep .drag-verify-container {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.card-text {
  color: #000;
}

::v-deep .v-input__slot {
  background-color: #fff !important;
}

::v-deep .vue-tel-input {
  background-color: #fff !important;
}
</style>
